import { Injectable } from "@angular/core";
import { LocalStorageService } from "./storage/local-storage.service";
import { AuthService } from "./auth/auth.service";
import { LoginResponse } from "@shared/schemas/complete-login";
import { DatadogService } from "./datadog.service";

interface ConversationFields {
	id: string;
	value: string | number | boolean;
}
type JwtFn = (jwtFn: (jwt: string) => void) => unknown;
interface ZendeskWindow extends Window {
	zE: (
		type: string,
		action: string,
		param?: JwtFn | ConversationFields[]
	) => void;
}

@Injectable({
	providedIn: "root"
})
export class ZendeskService {
	constructor(
		private localStorageService: LocalStorageService,
		private window: Window,
		private authService: AuthService,
		private datadogService: DatadogService
	) {}

	private logZendeskInitFailure(
		userLoginData: LoginResponse | null,
		jwt: string | null
	): void {
		this.datadogService.customLog(
			"error",
			"(Iniciar Zendesk) - Falha ao iniciar zendesk",
			{
				date: new Date().toLocaleString("pt-BR"),
				user: userLoginData,
				hasEmail: !!userLoginData?.email,
				hasJwt: !!jwt
			}
		);
	}

	private configureZendesk(jwt: string, email: string): void {
		const zendeskWindow = this.window as ZendeskWindow;

		zendeskWindow.zE("messenger", "loginUser", (callback) => callback(jwt));
		zendeskWindow.zE("messenger:set", "conversationFields", [
			{ id: "34882307616923", value: email } as ConversationFields
		]);
		zendeskWindow.zE("messenger", "show");
	}

	initZendesk(): void {
		const zendeskJwt = this.localStorageService.get<string>("jwtZendesk");
		const userLoginData = this.authService.getUserLoginData();

		if (!userLoginData || !zendeskJwt) {
			this.logZendeskInitFailure(userLoginData, zendeskJwt);
			return;
		}

		this.configureZendesk(zendeskJwt, userLoginData.email);
	}

	hideZendesk(): void {
		const zendeskWindow = this.window as ZendeskWindow;
		zendeskWindow.zE("messenger", "hide");
	}
}
